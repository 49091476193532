@import url('https://fonts.googleapis.com/css?family=Dosis:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Yellowtail');


$primary:#41D0CE; /* MAIN COLOR */
$secondary: #F7A652; /* SECONDARY COLOR */
$blk: #1e2123; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;
.top-pad {
	font-size: 1.3em;
	margin-top: 130px;
	a, li{
		font-size: 1.1em;
	}
}
.flash {
	display:none;
}
.navbar-default{
	background: $primary;
	border: none;
	color: $wht;

}
h1, h2 {
	font-family: 'Yellowtail', cursive;

}
nav {
	z-index: 1000;
	background: $blk;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 100px;
		display: flex;
		align-items: center;
		color: $wht;
		outline: 0;


		@media (max-width: 767px) {
		    height: 28px;
		    display: inline-block;
				line-height: 0;
		}

	    &:hover {
	    	background: darken($primary,10%);
	    	color: lighten($primary, 50%);
			transition: all 0.7s ease 0s;
			outline: 0;
	    }
	    &:focus {
	    	color: $wht;
	    	background: transparent;
	    	outline: 0;
	    }
	}
}

.navbar-toggle {
    border: 1px solid transparent;
    border-radius: 4px;
    background: white;
}
.cta {
	padding: 15px 20px;
	background: $blk;
	color: $wht;
	font-size: 1.2em;
	margin: 5px;
	outline: 0;
	&:hover{
		border-radius: 0px;
		color: $wht;
		background: darken($primary, 40%);
		transition: all 0.7s ease 0s;
		outline: 0;
	}
	&:focus, &:active {
		background: $blk !important;
		color: $wht !important;
		outline: 0 !important;
	}
}
.logo{
	width: 250px;
	@media(max-width: 767px){
		max-width: 150px;
	}
}
h1, h2, h3, p, a  {
		font-family: 'Dosis', sans-serif;
		font-size: 1.5em;
}
h1 {
	font-family: 'Yellowtail', cursive;
	font-weight: bold;
	color: $primary;
	font-size: 3em;
	@media (max-width: 767px){
		font-size: 2em;
	}
}

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $blk;
	border-radius: $border-radius;
	background: $blk;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($blk,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $blk;
		border: none;
	}
}

.modal-dialog {
	max-width: 250px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $blk;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($blk,20%);
		text-align: center;
		color: $blk;
	}
	input#username, input#password {
		outline: 1px solid black;
		@media (max-width: 767px) {
			font-size: 16px;
		}
	}
	button {
		background: $blk;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
			outline: 0;
		}
		&:focus, &:active {
			background: $blk;
			color: $wht;
			outline: 0;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header, .modal-footer {
	background:$blk;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: darken($primary, 3%);
	color: $footerLinks;
	p {
		font-size: 1em;
	}
	a {
		font-size: 1.2em;
		color: $footerLinks;
		white-space: nowrap;
		outline: 0;
		text-decoration: none;

		&:hover {
			color: lighten($footerLinks, 10%);
			outline: 0;
			text-decoration: none;
		}
		&:focus, &:active {
			color: $footerLinks;
			background: transparent;
			outline: 0;
			text-decoration: none;
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

section {
	background-size: cover;
	background-repeat: no-repeat;;
	background-position:  center;
}

.hero-banner {
	background-image:url('../img/bg1.jpg');
	background-position: top center;
	padding: 200px 0;
	p {
		font-family: 'Dosis', sans-serif;
		font-style: italic;
		font-size: 2em;
		color: darken($wht, 15%);
	}
	@media (max-width: 1024px){
		padding: 150px 0;
	}
	@media (max-width: 767px){
		padding: 90px 0;
	}
}

.trio-section {
	background-color: $secondary;
	color: $wht;
	padding: 50px 0;
	h1 {
		color:$wht;
		text-transform: capitalize;
		padding-bottom: 20px;
	}
	h3 {
		font-size: 2em;
		padding-bottom: 25px
	}
	.cta {
		background: $blk;
	}
	@media(max-width: 767px){
		padding: 30px 0;
	}
}
.form {
	background: $blk;
	padding: 20px 0;
	.container {
		.content-right {
			margin-top: 5%;
			@media (max-width: 991px) {
				margin-top: 0;
			}
			img{
				margin-bottom: 20px;
			}
			p {
				font-size: 2.3em;
				text-align: center;
				color:$wht;
				@media (max-width: 767px) {
					font-size: 1.7em;
				}
			}
		}
	}
}
.box-info {
	background-image: url('../img/lastbox.jpg');
	padding:350px 0;
	@media(max-width: 767px){
		padding: 100px 0 100px 0;
	}
	@media(min-width: 768px) and (max-width: 1024px){
		padding: 100px 0 100px 0;
	}
	h1 {
		color: $primary;
		font-size: 4em;
		@media (max-width: 767px){
			font-size: 3em
		}
		@media (max-width: 500px) {
			font-size: 2.2em;
		}
	}
	h2 {
		color: $blk;
		padding: 45px 0;
	}
	h3 {
		font-size: 3em;
		font-family: 'Dancing Script', cursive;
		color: darken($primary, 20%);
		padding-bottom: 35px;
	}
	p {
		font-size: 1.5em;
		margin-top: 25px;
	}

}

@media (max-width: 991px) {
	.couple {
		margin-top: 30px;
		max-width: 300px;
		width: 100%;
	}
}

.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
}

.btn:focus, .btn:active {
	background: $blk !important;
	color: $wht !important;
	outline: 0 !important;
}

.btn:hover{
	color: $wht;
	background: darken($primary,60%);
	transition: all 0.7s ease 0s;

}

.top-pad {
	h1,h2,h3 {
		font-size: 1.7em;
		@media (max-width: 767px) {
			font-size: 1.3em;
		}
	}
	p {
		font-size: 1.2em;
		@media (max-width: 767px) {
			font-size: 1em;
		}
	}
	font-family: 'Dosis', sans-serif;
}